import * as React from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { navigate } from "gatsby";
import { usePaystackPayment } from "react-paystack";
import { PaystackProps } from "react-paystack/dist/types";
import countryList from "react-select-country-list";
import { object, string } from "yup";
import Layout from "components/Layout";
import Seo from "components/Seo";

type Tagged<A, T> = A & { __tag?: T };
type E164Number = Tagged<string, "E164Number">;
type FormDataType = {
  fullName: string;
  phoneNumber: E164Number | string;
  email: string;
  country: string;
  amount: string;
  city: string;
  gender: string;
  communityExperience: string;
  previousCommunities: string;
  socials: string;
  learningGoals: string;
  moreInfo: string;
  access_key: string;
};

const error = "This field is required";
const FormDataSchema = object().shape({
  fullName: string().required(error),
  amount: string().required(error),
  email: string().email("Email address is invalid").required(error),
  country: string().required(error),
  city: string().required(error),
  learningGoals: string().required(error),
});
const initialState: FormDataType = {
  fullName: "",
  phoneNumber: "",
  email: "",
  country: "",
  amount: "",
  city: "",
  gender: "",
  communityExperience: "",
  previousCommunities: "",
  socials: "",
  learningGoals: "",
  moreInfo: "",
  access_key: "8397a598-3084-403d-a110-d7f93f4fd8b1",
};

const BootCampRegisterPage = () => {
  const options = React.useMemo(() => countryList().getData(), []);
  const [formData, setFormData] = React.useState(initialState);
  const [error, setError] = React.useState("");

  const config: PaystackProps = {
    reference: new Date().getTime().toString(),
    email: formData.email,
    firstname: formData.fullName.split(" ")[0],
    lastname: formData.fullName.split(" ")[1],
    phone: formData.phoneNumber,
    amount: Number(formData.amount),
    currency: "NGN",
    publicKey: process.env.GATSBY_PAYSTACK_API_KEY as string,
  };

  const onSuccess = async () => {
    try {
      const response = await axios.post(
        "https://api.web3forms.com/submit",
        formData,
      );

      if (response.status === 200) {
        // redirect to success page
        navigate("/bootcamp/success");
      } else {
        console.log(response);
        //  Throws Error
      }
    } catch (error) {
      //  Throws Error
      console.log({ error });
      setError(
        "An Error Occured with processing your request please contact the support team",
      );
    }
  };

  const onClose = () => {};
  const initializePayment = usePaystackPayment(config);

  React.useEffect(() => {
    initializePayment({ onSuccess, onClose });
  }, [formData]);

  return (
    <Layout>
      <Seo
        title="Register for CM Africa Bootcamp ‘24"
        description="A community for tech community builders, leaders, managers, developer advocates, and relations within and across Africa."
      />
      <section className="py-20">
        <div className="container max-w-screen-sm mx-auto px-6">
          <h1 className="text-4xl font-bold mb-10">
            Register for CM Africa Bootcamp ‘24
          </h1>
          {error && (
            <p className="text-red-500 text-center rounded-lg p-3 bg-red-100">
              {error}
            </p>
          )}
          <Formik
            initialValues={initialState}
            validationSchema={FormDataSchema}
            onSubmit={(values) => {
              setFormData(values);
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="mt-4">
                  <label htmlFor="amount" className="block mb-1">
                    Select a package <span className="text-red-500">*</span>
                  </label>
                  <Field
                    type="hidden"
                    name="access_key"
                    value="8397a598-3084-403d-a110-d7f93f4fd8b1"
                  />
                  <Field
                    as="select"
                    name="amount"
                    className="p-3  border rounded-lg w-full"
                  >
                    <option value=""> --Select a package-- </option>
                    <option value="15000000">
                      Standard $100 (NGN 150,000)
                    </option>
                    <option value="30000000">Gold $200 (NGN 300,000)</option>
                  </Field>
                  {errors.amount && touched.amount && (
                    <span className="text-red-500  mt-2  text-sm block">
                      <ErrorMessage name="amount" />
                    </span>
                  )}
                </div>
                <div className="mt-4">
                  <label htmlFor="fullName" className="block mb-1">
                    Full Name <span className="text-red-500">*</span>
                  </label>
                  <Field
                    type="text"
                    name="fullName"
                    id="fullName"
                    placeholder="Full Name"
                    className="p-3 border rounded-lg w-full"
                  />
                  {errors.fullName && touched.fullName && (
                    <span className="text-red-500  mt-2  text-sm block">
                      <ErrorMessage name="fullName" />
                    </span>
                  )}
                </div>
                <div className="mt-4">
                  <label htmlFor="email" className="block mb-1">
                    Email Address <span className="text-red-500">*</span>
                  </label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    className="p-3  border rounded-lg w-full"
                  />
                  {errors.email && touched.email && (
                    <span className="text-red-500  mt-2  text-sm block">
                      <ErrorMessage name="email" />
                    </span>
                  )}
                </div>
                <div className="mt-4">
                  <label htmlFor="gender" className="block mb-1">
                    Gender <span className="text-red-500">*</span>
                  </label>
                  <Field
                    as="select"
                    className="p-3  border rounded-lg w-full"
                    name="gender"
                  >
                    <option value="">--Select Gender--</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </Field>
                  {errors.gender && touched.gender && (
                    <span className="text-red-500  mt-2  text-sm block">
                      <ErrorMessage name="gender" />
                    </span>
                  )}
                </div>

                <div className="mt-4">
                  <label htmlFor="country" className="block mb-1">
                    Country <span className="text-red-500">*</span>
                  </label>
                  <Field
                    as="select"
                    className="p-3  border rounded-lg w-full"
                    name="country"
                  >
                    <option value="">--Select Country--</option>
                    {options.map(
                      (country: { label: string; value: string }) => (
                        <option key={country.value} value={country.value}>
                          {country.label}
                        </option>
                      ),
                    )}
                  </Field>
                  {errors.country && touched.country && (
                    <span className="text-red-500  mt-2  text-sm block">
                      <ErrorMessage name="country" />
                    </span>
                  )}
                </div>
                <div className="mt-4">
                  <label htmlFor="city" className="block mb-1">
                    City <span className="text-red-500">*</span>
                  </label>
                  <Field
                    type="text"
                    name="city"
                    id="city"
                    placeholder="City"
                    className="p-3  border rounded-lg w-full"
                  />
                  {errors.city && touched.city && (
                    <span className="text-red-500  mt-2  text-sm block">
                      <ErrorMessage name="city" />
                    </span>
                  )}
                </div>
                <div className="mt-4">
                  <label htmlFor="phoneNumber" className="block mb-1">
                    Phone Number
                  </label>
                  <Field
                    type="tel"
                    name="phoneNumber"
                    id="phoneNumber"
                    placeholder="Phone Number"
                    className="p-3  border rounded-lg w-full"
                  />
                </div>
                <div className="mt-4">
                  <label htmlFor="communityExperience" className="block mb-1">
                    ⁠Have you built a community before? (it’s okay if you have
                    never built one)
                  </label>
                  <Field
                    id="yes"
                    className="default:bg-secondary indeterminate:bg-gray-300 mr-1"
                    type="radio"
                    name="communityExperience"
                    value="yes"
                  />
                  <label htmlFor="yes" className="">
                    Yes
                  </label>
                  <Field
                    id="no"
                    className="default:bg-secondary indeterminate:bg-gray-300 ml-5 mr-1"
                    type="radio"
                    name="communityExperience"
                    value="no"
                  />
                  <label htmlFor="no" className="">
                    No
                  </label>
                  {errors.communityExperience && touched.communityExperience && (
                    <span className="text-red-500  mt-2  text-sm block">
                      <ErrorMessage name="communityExperience" />
                    </span>
                  )}
                </div>
                <div className="mt-4">
                  <label htmlFor="previousCommunities" className="block mb-1">
                    ⁠If yes above, what type of community have you built before?
                  </label>
                  <textarea
                    name="previousCommunities"
                    id="previousCommunities"
                    cols={20}
                    rows={3}
                    className="p-3  border rounded-lg w-full"
                  />
                </div>
                <div className="mt-4">
                  <label htmlFor="learningGoals" className="block mb-1">
                    What are your learning goals for the bootcamp?
                    <span className="text-red-500">*</span>
                  </label>
                  <Field
                    as="textarea"
                    name="learningGoals"
                    id="learningGoals"
                    cols={20}
                    rows={3}
                    className="p-3 border rounded-lg w-full"
                  />
                  {errors.learningGoals && touched.learningGoals && (
                    <span className="text-red-500  mt-2  text-sm block">
                      <ErrorMessage name="learningGoals" />
                    </span>
                  )}
                </div>
                <div className="mt-4">
                  <label htmlFor="socials" className="block mb-1">
                    Link to socials (Twitter, Instagram and LinkedIn)
                  </label>
                  <Field
                    as="textarea"
                    name="socials"
                    id="socials"
                    cols={20}
                    rows={3}
                    className="p-3  border rounded-lg w-full"
                  />
                </div>
                <div className="mt-4">
                  <label htmlFor="moreInfo" className="block mb-1">
                    Is there anything else you would like the team to know?
                  </label>
                  <Field
                    as="textarea"
                    name="moreInfo"
                    id="moreInfo"
                    cols={20}
                    rows={3}
                    className="p-3  border rounded-lg w-full"
                  />
                </div>
                <button
                  type="submit"
                  className="py-3.5 px-12 mt-10 border border-secondary text-white bg-secondary rounded-lg"
                >
                  Register
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </Layout>
  );
};

export default BootCampRegisterPage;
